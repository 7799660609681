* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
}

.header {
  padding: 0px 50px !important;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  height: 84px !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;

  padding: 0;
  background: #40948a;
}

.fixedHeaderContainer {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  text-align: left;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  margin: 0px auto;
  max-width: 1100px;
  padding: 0px 0px;
  width: 100%;
}

.wrapper-content {
  margin: 0px auto;
  max-width: 1100px;
  padding: 0px 0px;
  width: 90%;
}


.modal-cadastro div.ant-modal-header {
  padding: 14px 24px;
  color: rgba(255, 255, 255, 0.65);
  background: #40948a;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 3px 3px 0 0;
}

.modal-cadastro div.ant-modal-content {
  padding: 0px !important;
}

.modal-cadastro div.ant-modal-body {
  padding: 10px 10px 0px !important;
}

.modal-cadastro div.ant-modal-footer {
  padding: 0px 10px 10px !important;
}

.modal-cadastro div.ant-modal-title,
.ant-modal-close-x {
  color: #fff;
}






.ant-dropdown-button.ant-btn-group>.ant-btn:last-child:not(:first-child) {
  line-height: normal;
}


ul.ant-menu-dark {
  background: #40948a;
}

.logo {
  display: flex;
  align-items: center;
  /* Alinha verticalmente */
  justify-content: center;
  /* Alinha horizontalmente (opcional) */
}

a {
  color: #891635
}